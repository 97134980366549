import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { getUserInfo } from '../../actions/villageAction';
// import { useSearchParams } from 'react-router-dom';
import SearchBar from 'material-ui-search-bar';
import User from '../Activity/userDetail';
import { navigate, Link } from 'gatsby';
import { Button } from '@paljs/ui/Button';
import LoadingSpinner from '../loader';
import DataTable from 'react-data-table-component';
import { InputGroup } from '@paljs/ui/Input';
import { toast } from 'react-toastify';

const Users = ({ vName }) => {
  console.log('userVillage component', vName);

  const [rows, setRows] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [isLoading, showLoader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader(true);
        const response = await getUserInfo(`${vName}`);
        const data = response?.data;
        setRows(data);
        setOriginalRows(data);
        showLoader(false);
      } catch (error) {
        console.error(`Error in fetching user data of ${vName} village`, error);
        toast.error(`Error in fetching user data of ${vName} village`);
        showLoader(false);
      }
    };

    fetchData();
  }, [vName]);

  const tableColumns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <Link to={`/family-tree?idOfUser=${row.id}`}>
          {row.alive == 'dead' ? 'स्व. ' : ''}
          {row.name}
        </Link>
      ),
    },
    {
      name: 'Father Name',
      selector: (row) => row.father,
      sortable: true,
      cell: (row) => (
        <>
          {row.father ? (
            row.gender === 'female' ? (
              <div>
                D/o {row.father} <br /> {row?.husband ? `and W/o ${row.husband}` : ''}{' '}
              </div>
            ) : (
              <div>S/o {row.father}</div>
            )
          ) : null}
        </>
      ),
    },
    {
      name: 'subcaste(Gautra)',
      selector: (row) => row.gautra,
      sortable: true,
      cell: (row) => (
        <>
          <div>{row?.subcaste ? `${row.subcaste}(${row.gautra})` : `${row.gautra}`}</div>
        </>
      ),
    },
    {
      name: 'Gender',
      selector: (row) => row.gender,
      sortable: true,
    },
    {
      name: 'Mobile No.',
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: 'Registered Mobile No.',
      selector: (row) => row.registered_mobile,
      sortable: true,
    },
    {
      name: 'Action',
      grow: 2,
      left: true,
      wrap: true,
      cell: (row) => (
        <>
          <Link to={`/Activity/activityByUser?id=${row.id}`}>
            <Button shape={'Round'} size={'Tiny'} Status={'Success'}>
              BY USER
            </Button>
          </Link>
          <Link to={`/Activity/activityForUser?id=${row.id}`}>
            <Button shape={'Round'} size={'Tiny'} Status={'Success'}>
              FOR USER
            </Button>
          </Link>
          <a href={`/whatsapp/templates?node_id=${row.id}`}>
            <Button shape={'Round'} size={'Tiny'} Status={'Success'}>
              WhatsApp Templates
            </Button>
          </a>
        </>
      ),
    },
  ];

  // const requestSearch = (searchedVal: string) => {
  //   console.log("this is for searchval")
  //   const filteredRows = demo.filter((row) => {
  //     return Object.keys(row).some((key)=>
  //     row[key].toLowerCase().includes(searchedVal.toLowerCase()));
  //   });
  //   setRows(filteredRows);
  // };

  // const cancelSearch = () => {
  //   setSearched("");
  //   requestSearch(searched);

  const handleInputChange = (event) => {
    do_search(event.target.value);
  };
  const do_search = (v) => {
    const value = v.trim();
    const temp_rows = originalRows.filter((e) => JSON.stringify(e).indexOf(value) >= 0);
    setRows(temp_rows);
  };

  const villageUsers = (
    <>
      <InputGroup fullWidth size="Small" status="Info">
        <input type="text" placeholder="Search..." onChange={(event) => handleInputChange(event)} />
      </InputGroup>
      <DataTable
        title="Complaint List"
        columns={tableColumns}
        data={rows}
        pagination
        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
        paginationPerPage={100}
        striped
        dense
        noHeader
        highlightOnHover
      />
    </>
  );

  return <>{isLoading ? <LoadingSpinner message={`Loading Data For Users of ${vName} Village`} /> : villageUsers}</>;
};
export default Users;
