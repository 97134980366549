import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import UserList from './usersOfVillage';
import SelectVillage from '../family-tree/select-village';
import { navigate } from 'gatsby';

const filterVillage = () => {
  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  const villageNamefromURL = params.get('name');
  const [name, setName] = useState(villageNamefromURL);
  console.log('passed name in parent', name);

  useEffect(() => {
    const updateURL = () => {
      const queryParams = new URLSearchParams();
      queryParams.set('name', name);
      navigate(`?${queryParams.toString()}`);
    };

    updateURL();
  }, [name]);

  return (
    <>
      <div>Search For Village</div>
      <SelectVillage setVillage={setName} village={name} />
      {/* <ReactSearchAutocomplete
        items={rows}
        onSearch={handleOnSearch}
        onHover={handleOnHover}
        onSelect={handleOnSelect}
        onFocus={handleOnFocus}
        autoFocus
        formatResult={formatResult}
        placeholder="Search for village"
      /> */}
      <br />
      <div
        style={{
          textAlign: 'center',
          backgroundColor: 'whitesmoke',
          color: 'black',
          padding: '18px',
          fontSize: '25px',
        }}
      >
        {name}
      </div>
      <UserList vName={name} />
    </>
  );
};
export default filterVillage;
